import React from "react";

export const data_googleAds = {
  what_we_do: {
    title: "Dlaczego warto wybrać Google Ads/Adwords?",
    text: [
      <>
        <b>Płać tylko za wynik!</b> - w systemie Google Ads płacisz tylko za
        działania reklamowe, czyli kliknięcia, konwersje czy wyświetlenia strony
        docelowej. To największa przewaga reklamy Pay Per Click.
      </>,
      <>
        <b>Google Analitycs</b> – konfiguracja dodatkowych narzędzi takich jak
        Google Analytics, pozwala na dokładne mierzenie wyników i profesjonalną
        optymalizację działań, nie tylko w środowisku Google.
      </>,
      <>
        <b>Największy zasięg spośród wszystkich systemów</b> – żaden inny system
        nie zaproponuje Ci takiego zasięgu, jak Google. Twoja reklama może
        pojawić się wszędzie: w wyszukiwarce, w aplikacjach mobilnych,
        platformie Gmail czy na Youtubie.
      </>,
      <>
        <b>SEM to SEO i PPC</b> – Jeżeli chcesz, aby Twoje działania
        marketingowe w wyszukiwarce były na wysokim poziomie, musisz połączyć ze
        sobą wszystkie możliwe warianty. Nasz przepis to połączenie
        pozycjonowania z odpowiednimi działaniami Google Ads.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Planowanie",
        list: [
          "Brief",
          "Analiza dotychczasowych działań marketingowych",
          "Analiza rynku i konkurencji",
          "Określenie grupy odbiorców",
          "Koncepcja kompleksowej kampanii reklamowej",
        ],
      },
      {
        title: "Przygotowanie",
        list: [
          "Konfiguracje narzędzi analitycznych - Google Tag Manager, Google Analytics",
          "Zebranie informacji / materiałów",
          "Opracowanie grafik reklamowych",
          "Utworzenie kampanii",
        ],
      },
      {
        title: "Obsługa",
        list: [
          "Bieżąca obsługa reklam",
          "Analiza i optymalizacja dotychczasowych działań",
          "Raportowanie",
        ],
      },
    ],
  },
};
