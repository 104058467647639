import React from "react";
import SEO from "components/seo";
import useHerosTypesData from "shared/Hooks/PageHeaders/useHerosTypesData";
import { BigHero } from "components/BigHero";
import { OFFER_GOOGLEADS } from "shared/Types/heros";
import { OfferLayoutNew } from "components/OfferLayoutNew";
import { data_googleAds } from "../../data/offer/kampanie-ads";
import { useDesignOfferCase } from "shared/Hooks/CasesNew/useDesignOfferCase";

const GoogleAds = () => {
  const data = useHerosTypesData(OFFER_GOOGLEADS);
  const data_cases = useDesignOfferCase();
  const data_projects = Object.values(data_cases);

  return (
    <>
      <SEO title={data.title} description={data.seo} />
      <BigHero data={data} />
      <OfferLayoutNew data={data_googleAds} data_projects={data_projects} />
    </>
  );
};

export default GoogleAds;
